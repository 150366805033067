import { Flex, HStack, SimpleGrid, Textarea } from "@chakra-ui/react";
import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm";
import { TextK1 } from "../../../components/text/TextKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react";
import SelectK from "../../../components/select/SelectKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst";
import { LojaSelect } from "../../../layout/painel/MenuPainel";

export default function FormAPI( {open,setOpen,edit = null,setEdit}){  
    const [load,setLoadD] = useState(null);
    const [base,setBase] = useState(null);
    const [reload,setReload] = useState(false);
    useEffect(()=>{
        if(edit!==null){
            setLoadD(0);
            setTimeout(()=>{
                sendFormSocket({ modulo:'webhook',cmd:'info',form:{id:edit},callback:(result)=>{ 
                    setLoadD(1);
                    setBase(result);
                    setReload(true);
                   
                }});
            },1000);
        }else{
            setLoadD(1);
        } 
    },[])  
    let dados = {};
    
    let send ={loja:LojaSelect}; 
    if(base!== null){ 
        send={
            id      :   base.id,
            loja    :   LojaSelect
        }; 
        dados = {
            nome        :   base.nome,
            link        :   base.link 
        };
    } 
    if(!reload){
        return (
            <SendForm  dados = {{modulo:'webhook',cmd:'saveWebhook',form:send}}  
                initial={ dados } 
                schema={ 
                    object({ 
                        nome:   string().required('Nome do Webhook es obligatorio!'),
                        link:    string().required('URL es obligatorio!') 
                    })
                } 
                callback={(result)=>{ 
                    setOpen(false); 
                }}
                gap={'10px'}
                > 
                <PageHK   textAlign='left'>Webhook</PageHK>   
                <InputK  loaded={load} focus={true} type='name' label="Nome"   name="nome" placeholder="Nome do webhook"   autoComplete={false}/> 
                <InputK  type='site'    loaded={load}   label="URL" leftA={'HTTPS://'} leftAW={'5em'}  name="link" placeholder="www.meusite.com.br"   focus={false} autoComplete={false}/>
                     
                <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}> 
                    <ButtonKalyst loaded={load}  >
                        {edit==null?'Registrar Webhook':'Salvar Webhook'}
                    </ButtonKalyst> 
                    <LinkK onClick={()=>{
                        setOpen(false);
                        setEdit(null)
                    }}>
                        Cancelar
                    </LinkK>
                </Flex>
            </SendForm>
        )
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}