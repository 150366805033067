import { Flex, Skeleton, Stack} from "@chakra-ui/react";
import { PageHK, SubBHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendFormAdmin"; 
import { LinkK } from "../../../components/button/LinkKalyst";
import { useState } from "react";
import SelectK from "../../../components/select/SelectKalyst"; 

export default function FormAdminBass( {open,setOpen,edit = null,setEdit,subs,regsubs= []}){  
   
    const [load,setLoadD] = useState(1);
    const [base,setBase] = useState(null);
    const [code,setCode] = useState(null);
    const [reload,setReload] = useState(false);  
    
    let dados       =   {}; 
    if(edit!=null){
        regsubs.forEach((v)=>{
            if(v.id == edit){
                dados = v;
                if(code == null){
                    setCode(v.code);
                    setBase(v);
                    setReload(true);
                }
            }
        }) 
    } 
    let selectSubs  =   [];
    let subDados    =   {};

    subs.forEach((v)=>{
        subDados[v.code] = v; 
        var add = true;
        regsubs.forEach((api)=>{
            if(api.code == v.code){
                add =false;
            }
        });
        if(add){
            selectSubs.push({value:v.code,name:v.name});
        }
        
    }) 
  
    if(!reload){
        var sendOB = {
            obg:{},
            val:{},
            grl:{}
        }

        if(code  !=  null && code  !=  ''  ){ 
            subDados[code].chaves.forEach((n)=>{ 
                sendOB.obg[n.name] = string().required(n.title+' es obligatorio!');
                if(base!=null){
                    sendOB.val[n.name] =  base[n.name];  
                }  
            }); 
            if(base!=null){  
                sendOB.grl = base
            }   
        } 
        var send ={
            code: code
        }
        if(Object.keys(dados).length>0){
            send.id =dados.id
        }
        return (
             <> 
                <PageHK   textAlign='left'>  {Object.keys(dados).length==0?'Registrar BaaS':'Editar BaaS - '+ dados.nome}</PageHK> 
                <Stack pt='20px'> 
                {Object.keys(dados).length==0?
                
                <SelectK loaded={load} initial={{code:code!=null?code:''}} label={'Adquirente'} w="100%" onChange={(e)=>{   
                    setCode(e.target.value); 
                    if(e.target.value!=''){ 
                        regsubs.forEach((v)=>{ 
                            if(v.code == e.target.value){
                                setBase(v); 
                            } 
                        }); 
                    }else{
                        edit = null;
                        setBase(null);
                    } 
                    setReload(true);
                }}  placeholder="Selecione" value={selectSubs} name='code' ></SelectK> :
                null}
                    
                    {
                        code    !=  null && code  !=  '' ?
                        <SendForm  dados = {{modulo:'acquirer',cmd:'saveBasS',form:send}}  
                            initial={ sendOB.val }  
                            callback={(result)=>{  
                                setOpen(false); 
                            }}
                            gap={'10px'}
                        >   
                            <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}>
                               
                                <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>API Integração:</SubBHK> 
                                
                                    <Flex gap={'20px'} pt='10px'>
                                        {subDados[code].chaves.map((campo,i)=>{
                                            if(i <2){
                                                return  <InputK  key={code+'-'+campo.name}    type={campo.code} label={campo.title}   name={campo.name} placeholder={campo.title}   initial={ sendOB.val }    autoComplete={false}/>
                                            }else{
                                                return null;
                                            } 
                                        })} 
                                    </Flex>
                                    {subDados[code].chaves.length>2?
                                        <Flex gap={'20px'}>
                                            {subDados[code].chaves.map((campo,i)=>{
                                                if(i<2){
                                                    return null;
                                                }else{  
                                                    return <InputK key={code+'-'+campo.name} type={campo.type} label={campo.title} variantBT='admin'  name={campo.name} placeholder={campo.title}  initial={ sendOB.val }     autoComplete={false}/>
                                                } 
                                            })} 
                                        </Flex>:null
                                    }
                            </Stack>  
                            <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}>
                                <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>Taxas PIX OUT:</SubBHK>
                                <Flex w='full' gap={'10px'}> 
                                    <Flex w='100%' gap={'10px'}>
                                        <InputK  type='decimal'     label="Fixo"            placeholder="R$"        name="saqueFixo"  sigla={'R$'}   step='0,00'     focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                        <InputK  type='porcentagem' label="Porcentagem"     placeholder="%"         name="saquePoc"   max="100"       step='0,00'     focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                    </Flex> 
                                </Flex>
                            </Stack> 
                            {subDados[code].mensal?
                                <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}>
                                    <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>Taxas Mensal PIX OUT:</SubBHK>
                                    <Flex w='full' gap={'10px'}> 
                                        <Flex w='100%' gap={'10px'}>
                                            <InputK  type='integer'     label="Dia de  Vencimento"      placeholder="dia"       max='30'        name="saqueDia"                        focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                            <InputK  type='integer'     label="Pedidos Mínimos"         placeholder="Dias"      name="saqueNum"                                  focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                            <InputK  type='decimal'     label="Taxa Mensal"             placeholder="R$"        name="saqueMen"  sigla={'R$'}   step='0,00'     focus={false}   autoComplete={false} w='100%' initial={sendOB.grl}/>
                                        </Flex>
                                    </Flex>
                                </Stack>:
                                null
                            } 

                            <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}> 
                                {code== null?
                                    <Skeleton>
                                        <ButtonKalyst  variant={'admin'}>
                                            {base==null?'Registrar BaaS':'Salvar BaaS'}
                                        </ButtonKalyst>
                                    </Skeleton>
                                    :
                                    <ButtonKalyst  variant={'admin'}>
                                        {base==null?'Registrar BaaS':'Salvar BaaS'}
                                    </ButtonKalyst> 
                                }
                                
                                <LinkK onClick={()=>{
                                    setOpen(false);
                                }}>
                                    Cancelar
                                </LinkK>
                            </Flex>  
                        </SendForm>:   null  
                    }
                    
                </Stack>  
            </> 
        )
    }else{

        setTimeout(()=>{
            setReload(false)
        },0);
        return null;
    }
    
}




// import { Flex, HStack, SimpleGrid } from "@chakra-ui/react";
// import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
// import { InputK } from "../../../components/input/InputKalyst";
// import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
// import { object, string } from "yup";
// import { SendForm } from "../../../components/form/SendForm";
// import { TextK1 } from "../../../components/text/TextKalyst";
// import { LinkK } from "../../../components/button/LinkKalyst";
// import { useRef, useState } from "react";

// export default function FormClint( {open,setOpen,dados = {}}){ 
//     const [redirect,setRedirect] = useState(null);  
//     const end = useRef(null);
//     const [bairro,setBairro]    = useState((typeof dados.bairro!=='undefined'?dados.bairro:'')); 
//     const [cidade,setCidade]    = useState((typeof dados.cidade!=='undefined'?dados.cidade:''));  
    
//     return (
//         <SendForm  dados = {{modulo:'user',cmd:'register'}}  
//             initial={ dados }

//             schema={ 
//                 object({ 
//                     nome:       string().required('Razão Social es obligatorio!'),
//                     fantasia:   string().required('Nome Fantasia es obligatorio!'),
//                     email:      string().required('E-mail es obligatorio!'), 
//                     cnpj:       string().required('CNPJ es obligatorio!'), 
//                     telefone:   string().required('Telefone es obligatorio!'), 
//                 })
//             } 
//             callback={(result)=>{ 
              
//             }}
//             gap={'10px'}
//             > 
            
//         </SendForm>
//     )
// }