import {  Flex,  Stack, Text, Grid, GridItem,  TagLeftIcon, TagLabel, Tag, TabPanels, TabPanel, TabList, Tab, Tabs} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageH, PageHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket, user } from "../../../components/session/SessionAdmin"; 
import {  LuCheckCheck, LuReceipt, LuTruck, LuUsers2 } from "react-icons/lu";  
import ListResponsive, { ListaResultadoResponsive, ListPageResponsive } from "../../../components/list/ListResponsive"; 
import { LojaSelect } from "../../../layout/painel/MenuPainel"; 
import { FaArrowTrendUp, FaBarcode, FaCartShopping, FaCreditCard, FaPix, FaScaleBalanced } from "react-icons/fa6";
import { getFilter, setFilterFiltro, setFilterPage } from "../../../components/filter/FilterDate";
import md5 from "md5";
import { FilterKalyst } from "../../../components/filter/FIlterKalyst";
import { CardPrice } from "../../../components/card/CardValues";
import { BiMoney, BiMoneyWithdraw } from "react-icons/bi";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import BoxDialog from "../../../components/modal/BoxDialog"; 
import { TempoCronometro, viewPrice } from "../../../components/Function";
import { FaBalanceScale, FaCheckDouble } from "react-icons/fa";
import FormPagamento from "./FormPagamento";
import FilterPainel from "../../../components/filter/FilterPainel";
import SelectK from "../../../components/select/SelectKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { DataPickerKalyst } from "../../../components/input/DataPickerKalyst";


let session   = null;
export function PageAdminWithdraw(props){   
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({}); 
    const [alert,setAlert]    = useState({}); 
    
    const hoje = new Date(); 
    let tempLoja = null;
    let tempFilter = null;
    let dFilter   = null
    function BuscarDados(reload){  
        session = md5(user.id+'withdadminv2');
        dFilter = getFilter(session);
        if(LojaSelect !==tempLoja  || dFilter.up !==tempFilter ){
            tempLoja = LojaSelect
            tempFilter = dFilter.up
            reload = true; 
        }     
        sendFormSocket({campo:'pages', modulo:'withdraw',cmd:'list',form:{reload,loja:LojaSelect,filtro:dFilter},callback:(result)=>{   
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500); 
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        if(window.location.hash!=''){
            setTimeout(()=>{
                window.location.hash ='';
                setOpen(true);
                setEdit(null);
            })
           
        } 
 
        const status={
            'N'   :   {name:'Pago',       color:'green'}, 
            'C'   :   {name:'Cancelado',  color:'red'},
            'R'   :   {name:'Rejeitado',  color:'red'},
            'E'   :   {name:'Erro',  color:'red'},
            'F'   :   {name:'Falhou',   color:'orange'} , 
            'I'   :   {name:'Proces. BasS',   color:'orange'} , 
            'P'   :   {name:'Pendente',   color:'orange'} , 
        } 
        const titles = [
            {name:'Descrição',       campo:'title'        ,w:3}, 
            {name:'Status',     campo:'status'       ,w:1}, 
            {name:'Valor',      campo:'preco'        ,w:1}, 

            // {name:'Espera',  campo:'espera'   ,w:1} ,
            {name:'Registro',   campo:'registro'    ,w:1} 
        ]; 
        const boxInfo={
            v1:{value:0,info:0},
            v2:{value:0,info:0},
            v3:{value:0,info:0},
            v4:{value:0,info:0},
        }
        try {  
            boxInfo.v1.value = viewPrice(dados.saldos.saquesP); 
            
            boxInfo.v2.value = viewPrice(dados.saldos.saldo); 
            
            boxInfo.v3.value = viewPrice(dados.saldos.saquesD);
            boxInfo.v3.info  = viewPrice(dados.saldos.saques);
            
            boxInfo.v4.value = viewPrice(dados.saldos.vendasH);
            boxInfo.v4.info  = viewPrice(dados.saldos.vendas);
        } catch (error) {
            
        }
        const lista = [];
        dFilter = getFilter(session);
        let initial = {}
        try {
            Object.keys(dFilter.filtro).forEach((key)=>{
                initial[key]=dFilter.filtro[key].value;
            })
        } catch (error) {
            
        }  
        if(dados.reports.length>0){    
            dados.reports.forEach((cli)=>{  
                const data = new Date(cli.criacao);
                const dataP = new Date(cli.dataP);
                lista.push({
                    id          :   cli.id,
                    status      :   (
                        <Tag size={'sm'}  variant='subtle' colorScheme={typeof status[cli.situa] !=='undefined'?status[cli.situa].color:'gray'}> 
                            <TagLabel>{ typeof status[cli.situa] !=='undefined'?status[cli.situa].name:cli.situa}</TagLabel>
                        </Tag>
                    ),
                    title       :   cli.descricao,   
                    preco       :   cli.valor!=cli.valorP && cli.status=='paid'?''+cli.valorP.toLocaleString('pt-BR',{minimumFractionDigits:2}):'R$ '+(cli.valor*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
                    // espera      :   <Tag size={'sm'}   variant='subtle' colorScheme={'orange'}><TempoCronometro admin={true} time={data.getTime()} view = 'tempo'/> </Tag>,   
                    registro    :    new Date(data.getTime()-(data.getTimezoneOffset() * 60000)).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})  
                }) 
            }) 
        }   
        let fastV='';
        try {
            fastV = dFilter.filtro.fast.value;
        } catch (error) {
            
        }  

        //filter
        const viewFilter = (
            <Stack gap={'0px'}> 
                <Tabs variant='soft-rounded' colorScheme='green' size={'sm'}   pt={'0px'} px={'16px'}  pb='0'>
                    <TabList>
                        <Tab fontSize={'10px'}>Nome</Tab>
                        <Tab fontSize={'10px'}>Documento</Tab>
                        <Tab fontSize={'10px'}>Email</Tab>
                        <Tab fontSize={'10px'}>Telefone</Tab>
                    </TabList>
                    <TabPanels px={'0'}  pb='0'>
                        <TabPanel  px={'0'} pb='0' >
                            <InputK  focus={true}  type={'text'} label="Cliente Nome" size='sm'  name="LIKE_AND-user" placeholder={"Nome completo "}   autoComplete={false} initial={initial}/>  
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'cpfcnpj'} label="Cliente Documento" size='sm'  name="LIKE_AND-documento" placeholder={"cpf ou cnpj"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'email'} label="Cliente E-mail" size='sm'  name="LIKE_AND-email" placeholder={"email válido"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'phone'} label="Cliente Telefone" size='sm'  name="LIKE_AND-telefone" placeholder={"ddd + número"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <DataPickerKalyst  name="DATA_PERIODO-criacao" max='hoje'  initial={initial}/>
                <Stack gap='0px' px='16px'  >
                    <SelectK label={'Status'} placeholder="Selecione" size='sm'    value={Object.keys(status).map((key)=>{
                        return {value:key,name:status[key].name}
                    }) 
                    } name='IGUAL_AND-situa' initial={initial}/>
                    <InputK  focus={false}  type={'text'} label="Chave PIX" size='sm'  name="LIKE_AND-chave" placeholder={"Código ou Site"}   autoComplete={false} initial={initial}/>  
                </Stack> 
            </Stack> 
        )  
        var tempReload = null;
        return  ( 
            <Stack   w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start' overflowX='hidden'   minH={{base:'calc(100vh - 168px)',md:'calc(100vh - 152px)'}} pb='100px'  margin = '0 auto'  >
                <Grid
                    w={'full'}  
                    templateColumns={{base:'repeat(4, 1fr)',sm:'repeat(8, 1fr)',md:'repeat(4, 1fr)',lg:'repeat(8, 1fr)'}}
                    gap={'16px'}
                    py='16px'
                >
                    <GridItem colSpan={2}  > 
                        <CardPrice icon={BiMoney} title='Saques Total'  
                        value={boxInfo.v1.value}   
                        info={<Text color={'orange.500'} fontWeight='bold'>Pendente</Text>}
                        // infoM={'Pedidos não Pago'}
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaCheckDouble} title='Saldo Geral'  
                        value={boxInfo.v2.value}   
                         info={<Text color={'green.500'} fontWeight='bold'>Disponível</Text>}
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={BiMoneyWithdraw} title='Saques Totais' value={boxInfo.v3.value} 
                         info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v3.info}</Text>}
                         infoM={'Saques Pago'}/>
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaCartShopping} title='Vendas Geral' value={boxInfo.v4.value}
                        info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v4.info}</Text>}
                        infoM={'Vendas Total'}/>
                    </GridItem> 
                </Grid>
                <PageHK>Saques</PageHK>  
                <Stack gap='0px' w={'full'} >   
                    <FilterPainel variant='admin' dFilter={dFilter} session={session}  
                        onChange={()=>{
                            clearTimeout(tempReload);
                            tempReload = setTimeout(()=>{ 
                                tempLoja = null;
                                BuscarDados(true); 
                            },1000) 
                        }} 
                        ordem={[
                            {id:'id_desc'       ,name:'Mais Recentes'},
                            {id:'id_asc'        ,name:'Menos Recentes'},
                            {id:'valor_desc'    ,name:'Maior Valor'},
                            {id:'valor_asc'     ,name:'Menor Valor'},
                            {id:'method_desc'   ,name:'Método de Pagamento [Z-A]'},
                            {id:'method_asc'    ,name:'Método de Pagamento [A-Z]'},
                            {id:'status_desc'   ,name:'Status [Z-A]'},
                            {id:'status_asc'    ,name:'Status [A-Z]'}
                        ]}
                        fast={['method','status','cliente']}
                        viewFilter={viewFilter}
                    />   
                    <ListaResultadoResponsive session={session}  page={dados.page}/>
                    <ListResponsive titles={titles} dados={lista} cmd={
                        [
                            { icon:<LuReceipt />,name:'Editar', click:(d)=>{  
                                
                                setEdit(d.id);
                                setOpen(true); 
                            }}  
                        ]
                    } /> 
                    <ListPageResponsive onChange={()=>{ 
                        tempLoja = null;
                        BuscarDados(true);
                    }} session={session}  page={dados.page} />
                </Stack>
                <ModalPage open={open} setOpen={setOpen}>
                    <FormPagamento  open={open} setOpen={(el)=>{ 
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit} status ={status}/>
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}