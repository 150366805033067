import { Flex, FormControl, FormLabel, HStack, SimpleGrid, Switch, Textarea } from "@chakra-ui/react";
import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm";
import { TextK1 } from "../../../components/text/TextKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react";
import SelectK from "../../../components/select/SelectKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst";
import BoxPrice from "./BoxPrice";
import { AlertBox } from "../../../components/heading/AlertBox";
import { viewPrice } from "../../../components/Function";

export default function FormWithdraw( {open,setOpen,edit = null,setEdit}){  
    const [load,setLoadD] = useState(false);
    const [base,setBase] = useState({saldoD:0});
    const [valor,setValor] = useState(0);
    const [tipo,setTipo] = useState(1);
    const [terceiros,setTerceiros] = useState(null);
    const [reload,setReload] = useState(false);
    function BuscarDados(){
        sendFormSocket({ modulo:'withdraw',cmd:'infoSaldo',form:{},callback:(result)=>{  
            setBase(result.dados); 
            setReload(true); 
            setLoadD(true)
        }});
    }
    useEffect(()=>{  
        setTimeout(()=>{
            BuscarDados();
        },100); 
    },[])  
    let dados = {
        forma:tipo
    };
    let chaveTipo = {
        '1':'CNPJ',
        '2':'E-MAIL',
        '3':'TELEFONE',
        '4':'ALEATÓRIA',
    } 
    let mask = {
        '1':'CNPJ',
        '2':'EMAIL',
        '3':'PHONE',
        '4':'EMAIL',
    }
    let send ={
        valor   :   valor 
    };
     
    if(!reload){   
        return (
            <SendForm  dados = {{modulo:'withdraw',cmd:'saveWithdraw',form:send}}  
                initial={ dados } 
                schema={ 
                    object({  
                        forma:          string().required('Tipo de Chave é obrigatório'),
                        chave:          string().required('Chave é obrigatório')
                    })
                } 
                callback={(result)=>{   
                    BuscarDados();
                    // sendFormSocket({ modulo:'withdraw',cmd:'info',form:{},callback:(result)=>{  
                    //     setTimeout(()=>{
                    //         setBase(result.dados); 
                    //         setReload(true); 
                    //     },500);
                        
                    // }});
                    setOpen(false);  
                   
                }}
                gap={'10px'}
                > 
                <PageHK   textAlign='left'>Solicitar Saque</PageHK> 
                {base.rastP>0?<AlertBox title='Rastreios Pendentes' desc={'Você tem '+base.rastP+' '+(base.rastP>1?'pedidos':'pedido')+' sem rastreio, totalizando o saldo de R$'+viewPrice(base.saldoRAS)+' '} url='/reports#open'/>:null
                }
                <BoxPrice value={valor*0.01} saldo={(base.saldoD*0.01)} peds={1} onChange={(v)=>{
                    setValor(v);
                }}/>
                <Flex w={'full'} gap={{base:'0px',md:'20px'}}  direction={{base:'column',md:'row'}}>
                    <SelectK   label={'Tipo de Chave'}  placeholder="Selecione" onChange={(v)=>{ 
                        setTipo(v.target.value)
                        if(v.target.value>0){
                            setReload(true);
                            setLoadD(true)
                        }else{
                            setLoadD(false)
                        } 
                    }} value={ Object.keys(chaveTipo).map((key)=>{
                        return {value:key,name:chaveTipo[key]}
                    }) 
                    } name='forma' ></SelectK>  
                    <InputK  loaded={load} focus={false} type={mask[tipo]} label="Chave PIX"   name="chave" placeholder={"Chave "+chaveTipo[tipo]}   autoComplete={false}/>
                </Flex> 
                {load?
                    <Flex w={'full'} gap={{base:'0px',md:'20px'}} direction={{base:'column',md:'row'}}> 
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel htmlFor='email-alerts' mb='0'>
                                Chave pix de terceiros?
                            </FormLabel>
                            <Switch value={1}  onChange={(v)=>{
                               setTerceiros(v.target.checked?'S':null)
                            }} />
                        </FormControl>
                        
                    </Flex> 
                :null}  
                
                {load&& terceiros?
                    <Flex w={'full'} gap={{base:'0px',md:'20px'}} direction={{base:'column',md:'row'}}> 
                        <InputK  loaded={load} focus={true}  type={'text'} label="Titular"      name="titular"       placeholder={"Titular do Pix "+chaveTipo[tipo]}   autoComplete={false}/>
                        <InputK  loaded={load} focus={false} type={'cpfcnpj'} label="Documento"    name="documento"    placeholder={"CNPJ  do Pix "+chaveTipo[tipo]}   autoComplete={false}/>
                    </Flex> 
                :null}
                         
                <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}> 
                    <ButtonKalyst loaded={load}  >
                        Solicitar Saque
                    </ButtonKalyst> 
                    <LinkK onClick={()=>{
                        setOpen(false);
                        setEdit(null)
                    }}>
                        Cancelar
                    </LinkK>
                </Flex>
            </SendForm>
        )
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}