import { Stack, Text ,Icon, Flex, Spacer, Textarea } from "@chakra-ui/react"; 
import { TextK1 } from "../../../components/text/TextKalyst";
import { LuCheckCheck, LuChevronRight, LuFileWarning, LuTimer, LuUpload } from "react-icons/lu";
import { useState } from "react";
import ModalPage from "../../../components/modal/ModalPage";
import { InputUpload } from "../../../components/input/InputUpload";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { SendForm } from "../../../components/form/SendForm";
import { InputK } from "../../../components/input/InputKalyst";
import { object, string } from "yup";
import { HK4 } from "../../../components/heading/HeadingKalyst";
import SelectK from "../../../components/select/SelectKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { AlertBox } from "../../../components/heading/AlertBox"; 
import { bancos,estados, wsCEP, wsCNPJ } from "../../../components/Function";

export default function FormDocument({fase,setFase,dados,BuscarDados}){
    let viewForm = null;
    const [open,    setOpen]    = useState(false);  
    const [upload,  setUpload]    = useState({});  
    const [formats, setFormats]    = useState([]); 
    const [size,    setSize]    = useState(1); 
    const [editI,   setEditI]  = useState(null);
    const [editL,   setEditL]  = useState(null);
    const [editR,   setEditR]  = useState(null);
    const [editE,   setEditE]  = useState(null);
    const [editB,   setEditB]  = useState(null); 
    const [reset,   setReset]  = useState(false); 
    const [end, setEnd] = useState({})
    switch (fase) { 
        case 0:
            viewForm = (
                <>
                    {dados.tipos.map((t)=>{
                        var file = null;
                        var titulo= 'Enviar '+t.nome;
                        var msg =   'Enviar fotos dos Documentos';
                        var color = '';
                        var iconV = LuUpload;
                        var sendFile = true;
                        if(dados.docs.length>0){
                            dados.docs.forEach((v)=>{
                                if(v!== null && v.codtip==t.id){
                                    file = v;
                                }
                            })
                        }
                        if(file!==null){
                            switch(file.situa){
                                case 'R':
                                    iconV = LuFileWarning  ;
                                    titulo= ''+t.nome+' em Recusada';
                                    msg =    file.obs
                                    color = 'red';
                                break;
                                case 'P':
                                    iconV = LuTimer;
                                    titulo= ''+t.nome+' em análise';
                                    msg =   'Previsão de revisão é para '+(new Date(file.data_revisao).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit'}))
                                    color = 'orange';
                                    sendFile = false
                                break;
                                case 'N':
                                    iconV = LuCheckCheck;
                                    titulo= ''+t.nome+' aprovado';
                                    msg =   'Arquivo aprovado'
                                    color = 'green';
                                    sendFile = false
                                break;
                            }
                        } 
                        return (
                            <Stack key={'tipo'+t.id} cursor={'pointer'} position={'relative'} w={'100%'} borderRadius='4px' minH ='80px' p={'20px'} bg={'lightTema1.200'} _dark={{bg:'darkTema1.200'}} gap='0px' align='flex-start' justify={'center'} onClick={()=>{
                                if(sendFile == true){
                                    setUpload({
                                        title: 'Enviar '+t.nome,
                                        tipo  : t
                                    }); 
                                    setSize(t.size);
                                    setFormats(t.formats);
                                    setOpen(true);
                                } 
                            }}>
                                <Flex width={'100%'} align={'center'} gap={'15px'}>
                                    {(file !== null)?<Icon    color={color} _dark={{color:color}} as={iconV} fontSize='30px'/>:null}
                                    <Stack>
                                        <Text fontWeight='bold' fontSize={'20px'} lineHeight='24px' _dark={{color:'textDark.100'}}>{titulo}</Text>
                                        <Text fontSize={'13px'} lineHeight='16px'  color={(file == null)?'textLight.100':color} _dark={{color:(file == null)?'textDark.200':color}} >{msg}</Text>
                                    </Stack>
                                </Flex>
                                {sendFile?<Icon position={'absolute'} right='10px'  color={(file == null)?'textLight.200':color} _dark={{color:(file == null)?'textDark.100':color}} as={LuChevronRight} fontSize='30px'/>:null}
                            </Stack>
                        ) 
                    })} 
                    <ButtonKalyst w='100%' onClick={()=>{
                        setFase(1);
                    }}>Próximo Passo</ButtonKalyst>
                </>
            )
        break; 
        case 1:
            if(editI==null){
                if(dados.user.sysInf == 'P' || dados.user.sysInf == 'N'){
                    setEditI(true);
                }else{
                    setEditI(false);
                }
            }
            viewForm = ( 
                <>
                    {(dados.user.sysInf == 'P')?<AlertBox title='Dados Pendentes Aprovação' desc={'Dados em avaliação, prazo estimado para conclusão é  '+(new Date(dados.user.data_info_prazo).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit'}))} status="warning"/>:null}  
                    {(dados.user.sysInf == 'N')?<AlertBox title='Dados Aprovados' desc={'Dados aprovados com sucesso!' } status="success"/>:null}  
                    {(dados.user.sysInf == 'R')?<AlertBox title='Dados Reprovados' desc={dados.user.obsInfo } status="error"/>:null}  
                    
                    <SendForm  dados = {{modulo:'user',cmd:'saveUserInfo'}}  
                        initial={{
                            razao           :   dados.user.nome==null?'':dados.user.nome,
                            fantasia        :   dados.user.fantasia==null?'':dados.user.fantasia,
                            inscricao       :   dados.user.inscricao==null?'':dados.user.inscricao.split('T')[0],
                            cnpj            :   dados.user.documento==null?'':dados.user.documento,
                            telefone        :   dados.user.telefone==null?'':dados.user.telefone,
                            email           :   dados.user.email==null?'':dados.user.email,
                            atiPrincipal    :   dados.user.atiPrincipal==null?'':dados.user.atiPrincipal, 
                            atiSecundaria   :   dados.user.atiSecundaria==null?'':dados.user.atiSecundaria 
                        }}
                        schema={ 
                            object({ 
                                razao:          string().required('Razão Social es obligatorio!'),
                                inscricao:      string().required('Abertura es obligatorio!'),
                                fantasia:       string().required('Nome Fantasia es obligatorio!'),
                                cnpj:           string().required('CNPJ es obligatorio!'),  
                                email:          string().required('E-mail es obligatorio!'),  
                                telefone:       string().required('Telefone es obligatorio!'),  
                                atiPrincipal:   string().required('Atividade Principal es obligatorio!')   
                            })
                        } 
                        callback={(result)=>{ 
                            setEditI(true);
                            setFase(2);
                        }}
                        gap={'10px'}
                        >    
                        <Flex w={'full'} flexDirection={{base:'column', md:"row"}} gap={{base:'0', md:"20px"}}>
                            <InputK disabled={editI}  onChange={(valor,error)=>{ 
                               if(error.valid){
                                //    console.log('v',valor,'e',error,'D');
                               } 
                           }} type='cnpj' label="CNPJ"   name="cnpj" placeholder="00.000.000/0000-00"   autoComplete={false}/>
                            <InputK disabled={editI} type='text' label="Fantasia"   name="fantasia" placeholder="Nome Fantasia"   focus={false} autoComplete={false}/>
                            
                        </Flex>
                        <Flex w={'full'}  gap='20px'>
                            <InputK disabled={editI}  type='text' label="Razão Social"       name="razao"     placeholder="Razão Social"   focus={true} autoComplete={false}/>
                            <InputK disabled={editI}  type='date' label="Data de Abertura"    name="inscricao" placeholder="dd/mm/aaaa"  w={{base:'130px',md:'160px',lg:'170px'}}  autoComplete={false}/>
                        </Flex>
                        
                        <Flex w={'full'} flexDirection={{base:'column', md:"row"}} gap={{base:'0', md:"20px"}}> 
                            <InputK disabled={editI} type='email' label="Email" name="email" placeholder="Digite seu email"  w='100%'    autoComplete={false}/>
                            <InputK disabled={editI} type='phone' label="Telefone" name="telefone" placeholder="Telefone" w='100%'   autoComplete={false}/>
                        </Flex>
                        <InputK disabled={editI} type='text' label="Código e descrição da atividade principal" name="atiPrincipal"      placeholder="00.00-0-00 - Descrição da atividade "       autoComplete={false}/>
                        <InputK as={Textarea} disabled={editI} type='text' label="Código e descrição da atividade secundária" name="atiSecundaria"    placeholder="00.00-0-00 - Descrições das atividades"       autoComplete={false}/>
                       

                        <Flex justify={'space-between'} align={'center'} mt={'16px'} gap={"16px"}>
                            <LinkK onClick={()=>{
                                setEditI(true);
                                setFase(0);
                            }}>voltar para anterior</LinkK>
                            <Spacer/>
                            {!editI && dados.user.sysInf !=null ?
                                <ButtonKalyst type="reset" w='auto' variant={'outline'}  onClick={(e)=>{
                                    setReset(true); 
                                    setEditI(true);
                                }}>
                                    Cancelar
                                </ButtonKalyst>:
                                null  
                            }
                            {editI?
                                <ButtonKalyst  w='auto' variant={'outline'} onClick={(e)=>{
                                     e.preventDefault()
                                    setEditI(false);
                                }}>
                                    Editar
                                </ButtonKalyst>:
                                <ButtonKalyst w='auto'>
                                    Salvar
                                </ButtonKalyst>
                            }
                            
                        </Flex> 
                    </SendForm>
                </> 
            ) 
        break;
        case 2:
            if(editL==null){
                if(dados.user.sysLoj == 'P' || dados.user.sysLoj == 'N'){
                    setEditL(true);
                }else{
                    setEditL(false);
                }
            }
            viewForm = ( 
                <>
                    {(dados.user.sysLoj == 'P')?<AlertBox title='Dados Pendentes Aprovação' desc={'Dados em avaliação, prazo estimado para conclusão é  '+(new Date(dados.user.data_loja_prazo).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit'}))} status="warning"/>:null}  
                    {(dados.user.sysLoj == 'N')?<AlertBox title='Dados Aprovados' desc={'Dados aprovados com sucesso!' } status="success"/>:null}  
                    {(dados.user.sysLoj == 'R')?<AlertBox title='Dados Reprovados' desc={dados.user.obsLoj } status="error"/>:null}  
                    
                    <SendForm  dados = {{modulo:'user',cmd:'saveUserLoja'}}  
                        initial={{
                            faturamento :   dados.user.faturamento==null?'':dados.user.faturamento, 
                            ticket      :   dados.user.tckMedio==null?'':dados.user.tckMedio, 
                            fisico      :   dados.user.prdFisico==null?'':dados.user.prdFisico,
                            site        :   dados.user.site==null?'':dados.user.site,
                            descricao   :   dados.user.desTrabalho==null?'':dados.user.desTrabalho
                        }}
                        schema={ 
                            object({ 
                                faturamento:    string().required('Faturamento es obligatorio!'),
                                ticket:         string().required('Ticket Médio es obligatorio!'),
                                site:           string().required('Site es obligatorio!'),
                                fisico:         string().required('Selecionar uma opção es obligatorio!'),  
                                descricao:      string().required('Descrição es obligatorio!')
                            })
                        } 
                        callback={(result)=>{ 
                            setEditL(true); 
                            setFase(3);
                        }}
                        gap={'10px'}
                        >    
                        <Flex w={'full'} flexDirection={{base:'column', md:"row"}} gap={{base:'0', md:"20px"}}>
                            <InputK  type='number' disabled={editL} label="Faturamento" leftA={'R$'} leftAW={'3em'}   name="faturamento" placeholder="Média geral"   focus={true} autoComplete={false}/>
                            <InputK  type='number' disabled={editL} label="Ticket" leftA={'R$'} leftAW={'3em'}  name="ticket" placeholder="Média pagamento"   focus={false} autoComplete={false}/>
                            <SelectK disabled={editL} label={'Produto físico?'} placeholder="Selecione" value={[{value:'S',name:'SIM'},{value:'N',name:'NÃO'}]} name='fisico' ></SelectK>
                        </Flex>
                        <InputK  type='site' disabled={editL} label="Site" leftA={'https://'}  name="site" placeholder="meusite.com.br"     autoComplete={false}/>
                        <InputK  type='text' disabled={editL} label="Descrição" name="descricao" placeholder="Descreva seu negócio"    autoComplete={false}/>
                        
                        <Flex justify={'space-between'} align={'center'} mt={'16px'} gap={"16px"}>
                            <LinkK onClick={()=>{
                                setEditL(true);
                                setFase(1);
                            }}>voltar para anterior</LinkK>
                            <Spacer/>
                            {!editL&& dados.user.sysLoj !=null ?
                               
                                <ButtonKalyst type="reset" w='auto' variant={'outline'}  onClick={(e)=>{
                                    setReset(true); 
                                    setEditL(true);
                                }}>
                                    Cancelar
                                </ButtonKalyst>:
                                 null
                            }
                            {editL?
                                <ButtonKalyst  w='auto' variant={'outline'} onClick={(e)=>{
                                     e.preventDefault()
                                    setEditL(false);
                                }}>
                                    Editar
                                </ButtonKalyst>:
                                <ButtonKalyst w='auto'>
                                    Salvar
                                </ButtonKalyst>
                            }
                            
                        </Flex> 
                    </SendForm>
                </>
                 
            ) 
        break
        case 3:
            if(editR==null){
                if(dados.user.sysRep == 'P' || dados.user.sysRep == 'N'){
                    setEditR(true);
                }else{
                    setEditR(false);
                }
            } 
            viewForm = ( 
                <> 
                    {(dados.user.sysRep == 'P')?<AlertBox title='Dados Pendentes Aprovação' desc={'Dados em avaliação, prazo estimado para conclusão é  '+(new Date(dados.user.data_rep_prazo).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit'}))} status="warning"/>:null}  
                    {(dados.user.sysRep == 'N')?<AlertBox title='Dados Aprovados' desc={'Dados aprovados com sucesso!' } status="success"/>:null}  
                    {(dados.user.sysRep == 'R')?<AlertBox title='Dados Reprovados' desc={dados.user.obsRep } status="error"/>:null}  
                    <SendForm  dados = {{modulo:'user',cmd:'saveUserRep'}}  
                        initial={{
                            nome        :   dados.user.nomeRep ==null?'':dados.user.nomeRep,
                            cpf         :   dados.user.cpfRep==null?'':dados.user.cpfRep,
                            rg          :   dados.user.rgRep==null?'':dados.user.rgRep,
                            telefone    :   dados.user.telefoneRep==null?'':dados.user.telefoneRep,
                            nascimento  :   dados.user.nascimentoRep==null?'':dados.user.nascimentoRep.split('T')[0],
                            mae         :   dados.user.maeRep==null?'':dados.user.maeRep 
                        }}
                        schema={ 
                            object({ 
                                nome:       string().required('Nome es obligatorio!'),
                                cpf:        string().required('CPF es obligatorio!'),
                                rg:         string().required('RG es obligatorio!'),
                                telefone:   string().required('Telefone uma opção es obligatorio!'),  
                                nascimento: string().required('Nascimento es obligatorio!'),  
                                mae:        string().required('Nome da mãe es obligatorio!')
                            })
                        } 
                        callback={(result)=>{ 
                            setFase(4);
                        }}
                        gap={'10px'}
                        >   
                        <InputK  type='name' disabled={editR} label="Nome do Representante"   name="nome" placeholder="Nome completo"    focus={true}  autoComplete={false}/>
                        <Flex w={'full'} flexDirection={{base:'column', md:"row"}} gap={{base:'0', md:"20px"}}>
                            <InputK  type='cpf' disabled={editR} label="CPF do Representante" name="cpf"  placeholder="000.000.000-00"   focus={false} autoComplete={false}/>
                            <InputK  type='rg'  disabled={editR} label="RG do Representante"  name="rg"       placeholder="000000000"   focus={false} autoComplete={false}/>
                        </Flex> 
                        <Flex w={'full'} flexDirection={{base:'column', md:"row"}} gap={{base:'0', md:"20px"}}>
                            <InputK  type='phone' disabled={editR}   label="Telefone"    name="telefone"     placeholder="Telefone do Representante"   focus={false} autoComplete={false}/>
                            <InputK  type='date'  disabled={editR} label="Nascimento"  name="nascimento"   placeholder={'dd/mm/aaaa'}   focus={false} autoComplete={false}/>
                        </Flex> 
                        <InputK  type='name' disabled={editR} label="Nome da Mãe"   name="mae" placeholder="Nome da Mãe do Representante"     autoComplete={false}/>
                        <Flex justify={'space-between'} align={'center'} mt={'16px'} gap={'16px'}>
                        <LinkK onClick={()=>{
                                setFase(2);
                                setEditR(true);
                            }}>voltar para anterior</LinkK>
                            <Spacer/>
                            {!editR && dados.user.sysRep !=null ? 
                                <ButtonKalyst type="reset" w='auto' variant={'outline'}  onClick={(e)=>{
                                    setReset(true); 
                                    setEditR(true);
                                }}>
                                    Cancelar
                                </ButtonKalyst>:
                                 null
                            }
                            {editR?
                                <ButtonKalyst  w='auto' variant={'outline'} onClick={(e)=>{
                                    e.preventDefault()
                                    setEditR(false);
                                }}>
                                    Editar
                                </ButtonKalyst>:
                                <ButtonKalyst w='auto'>
                                    Salvar
                                </ButtonKalyst>
                            }
                        </Flex>  
                    </SendForm> 
                </>
            ) 
        break;
        case 4:
            if(editE==null){
                if(dados.user.sysEnd == 'P' || dados.user.sysEnd == 'N'){
                    setEditE(true);
                }else{
                    setEditE(false);
                } 
            }
            viewForm = ( 
                <>
                    {(dados.user.sysEnd == 'P')?<AlertBox title='Dados Pendentes Aprovação' desc={'Dados em avaliação, prazo estimado para conclusão é  '+(new Date(dados.user.data_end_prazo).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit'}))} status="warning"/>:null}  
                    {(dados.user.sysEnd == 'N')?<AlertBox title='Dados Aprovados' desc={'Dados aprovados com sucesso!' } status="success"/>:null}  
                    {(dados.user.sysEnd == 'R')?<AlertBox title='Dados Reprovados' desc={dados.user.obsEnd } status="error"/>:null}  
                    
                    <SendForm  dados = {{modulo:'user',cmd:'saveUserEnd'}}  
                        initial={{ 
                            cep             :   dados.user.cep==null?'':dados.user.cep,
                            rua             :   typeof end.rua!='undefined'?end.rua:dados.user.rua==null?'':dados.user.rua,
                            num             :   dados.user.num==null?'':dados.user.num,
                            complemento     :   dados.user.complemento==null?'':dados.user.complemento,
                            bairro          :   typeof end.bairro!='undefined'?end.rua:dados.user.bairro==null?'':dados.user.bairro,
                            cidade          :   typeof end.cidade!='undefined'?end.rua:dados.user.cidade==null?'':dados.user.cidade ,
                            estado          :   dados.user.estado==null?'':dados.user.estado, 
                            pais            :   dados.user.pais==null?'':dados.user.pais
                        }}
                        schema={ 
                            object({ 
                                cep:        string().required('CEP es obligatorio!'),
                                rua:        string().required('Endereço es obligatorio!'), 
                                bairro:     string().required('Bairro es obligatorio!'),  
                                cidade:     string().required('Cidade es obligatorio!'),  
                                estado:     string().required('Estado es obligatorio!'),  
                                pais:       string().required('País es obligatorio!')
                            })
                        } 
                        callback={(result)=>{ 
                            setFase(5);
                        }}
                        gap={'10px'}
                        >   
                        <Flex w={'full'} gap={{base:'10px', md:"20px"}}>
                            <InputK  type='cep'  disabled={editE} label="CEP" name="cep" w={{base:'130px',md:'160px',lg:'170px'}}  placeholder="00000-000"   focus={typeof end.rua =='undefined'?true:false} autoComplete={false} onChange={(value,error)=>{
                                 if(value.length==9){
                                    wsCEP(value.replace('-',''),(d)=>{ 
                                        setEnd({
                                            rua:d.logradouro, 
                                            cidade:d.localidade,
                                            uf:d.uf,
                                            pais:'BR'
                                        })
                                        setReset(true);
                                    }) 
                                } 
                            }}/>
                            <InputK  type='text'  disabled={editE} label="Endereço"  name="rua"       placeholder="rua,cond,av..."   focus={false} autoComplete={false}/>
                        </Flex> 
                        <Flex w={'full'} gap={{base:'10px', md:"20px"}}>
                            <InputK  type='integer' w={{base:'80px',md:'100px',lg:'120px'}}                disabled={editE}   label="Número"    name="num"     placeholder="0"   focus={typeof end.rua =='undefined'?false:true} autoComplete={false}/>
                            <InputK  type='text'   w={{base:"calc(60% - 80px)",md:"calc(60% - 100px)",lg:"calc(50% - 120px)"}}    disabled={editE} label="Complemento"  name="complemento"   placeholder={'bl,ap,lt...'}   focus={false} autoComplete={false}/>
                            <InputK  type='text'   w="calc(40%)"            disabled={editE} label="Bairro"  name="bairro"   placeholder={'nome do Bairro'}   focus={false} autoComplete={false}/>
                        </Flex> 
                        <Flex w={'full'}  gap={{base:'10px', md:"20px"}}>
                            <InputK  type='text'   w="calc(40%)" disabled={editE} label="Cidade"  name="cidade"         placeholder={'Nome da Cidade'}   focus={false} autoComplete={false}/>
                            <SelectK disabled={editE} label={'Estado'}  w="calc(40%)" placeholder="Selecione" value={estados} name='estado' ></SelectK> 
                            <SelectK disabled={editE} label={'País'}  w="calc(30%)" placeholder="Selecione" value={[{value:'BR',name:'Brasil'}]} name='pais' ></SelectK> 
                        </Flex> 
                        <Flex justify={'space-between'} align={'center'} mt={'16px'} gap={'16px'}>
                        <LinkK onClick={()=>{
                                setFase(3);
                                setEditR(true);
                            }}>voltar para anterior</LinkK>
                            <Spacer/>
                            {!editE && dados.user.sysEnd !=null ?
                            
                                <ButtonKalyst type="reset" w='auto' variant={'outline'}  onClick={(e)=>{
                                    setReset(true);
                                    setTimeout(()=>{
                                        setReset(false);
                                    },50)
                                    setEditE(true);
                                }}>
                                    Cancelar
                                </ButtonKalyst>:
                                null
                            }
                            {dados.user.sysEnd =='N'?null:editE?
                                <ButtonKalyst  w='auto' variant={'outline'} onClick={(e)=>{
                                    e.preventDefault()
                                    setEditE(false);
                                }}>
                                    Editar
                                </ButtonKalyst>:
                                <ButtonKalyst w='auto'>
                                    Salvar
                                </ButtonKalyst>
                            }
                        </Flex>  
                    </SendForm> 
                </>
            ) 
        break;
        case 5:
            if(editB==null){
                if(dados.user.sysBan == 'P' || dados.user.sysBan == 'N'){
                    setEditB(true);
                }else{
                    setEditB(false);
                }
            } 
            viewForm = ( 
                <>
                    {(dados.user.sysBan == 'P')?<AlertBox title='Dados Pendentes Aprovação' desc={'Dados em avaliação, prazo estimado para conclusão é  '+(new Date(dados.user.data_ban_prazo).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit'}))} status="warning"/>:null}  
                    {(dados.user.sysBan == 'N')?<AlertBox title='Dados Aprovados' desc={'Dados aprovados com sucesso!' } status="success"/>:null}  
                    {(dados.user.sysBan == 'R')?<AlertBox title='Dados Reprovados' desc={dados.user.obsBanco } status="error"/>:null}  
                    
                    <SendForm  dados = {{modulo:'user',cmd:'saveUserBan'}}  
                        initial={{ 
                            banco           :   dados.user.banco==null?'':dados.user.banco,
                            agencia         :   dados.user.agencia ==null?'':dados.user.agencia,
                            agenciaDig      :   dados.user.agenciaDig==null?'':dados.user.agenciaDig,
                            conta           :   dados.user.conta ==null?'':dados.user.conta,
                            contaDig        :   dados.user.contaDig==null?'':dados.user.contaDig 
                        }}
                        schema={ 
                            object({ 
                                banco:       string().required('Banco es obligatorio!'),
                                agencia:     string().required('Agência es obligatorio!'), 
                                conta:       string().required('Conta es obligatorio!')   
                            })
                        } 
                        callback={(result)=>{ 
                            setFase(0);
                        }}
                        gap={'10px'}
                        >   
                        <SelectK disabled={editB} label={'Banco'} placeholder="Selecione" value={bancos} name='banco' ></SelectK> 
                        <Flex w={'full'} gap='20px'>
                         
                                <InputK  type='agencia' w="calc(50% - 90px)" disabled={editB} label="Agência" name="agencia"   placeholder="0000000"   focus={false} autoComplete={false}/>
                                <Text w={'10px'}   mt={'31px'}   fontSize={'26px'}>-</Text>
                                <InputK  type='integer'   mt={'29px'} w="60px" disabled={editB} label="" max="9" name="agenciaDig"       placeholder="0"   focus={false} autoComplete={false}/>
                        
                                <InputK  type='agencia'  w="calc(50% - 90px)" disabled={editB} label="Conta" name="conta"   placeholder="0000000"   focus={false} autoComplete={false}/>
                                <Text w={'10px'} mt={'31px'}   fontSize={'26px'}>-</Text>
                                <InputK  type='integer' textAlign='center' mt={'29px'} w="60px" disabled={editB} label="" max="9" name="contaDig"       placeholder="0"   focus={false} autoComplete={false}/>
                            
                        </Flex>   
                        <Flex justify={'space-between'} align={'center'} mt={'16px'} gap={'16px'}>
                        <LinkK onClick={()=>{
                                setFase(4);
                                setEditB(true);
                            }}>voltar para anterior</LinkK>
                            <Spacer/>
                            {!editB&& dados.user.sysBan !=null ?
                                <ButtonKalyst type="reset" w='auto' variant={'outline'}  onClick={(e)=>{
                                    setReset(true);
                                    setTimeout(()=>{
                                        setReset(false);
                                    },50)
                                    setEditB(true);
                                }}>
                                    Cancelar
                                </ButtonKalyst>:
                                null
                                
                            }
                            {dados.user.sysBan =='N'?null:editB?
                                <ButtonKalyst  w='auto' variant={'outline'} onClick={(e)=>{
                                    e.preventDefault()
                                    setEditB(false);
                                }}>
                                    Editar
                                </ButtonKalyst>:
                                <ButtonKalyst w='auto'>
                                    Salvar
                                </ButtonKalyst>
                            }
                        </Flex>  
                    </SendForm> 
                </>
            ) 
        break;
    } 
    if(reset){
        setTimeout(()=>{
            setReset(false);
        },50);
        return null;
    }else{
        return <Stack> 
            {viewForm}
            <ModalPage open={open} setOpen={setOpen}> 
                <InputUpload props={upload} types={formats} size={size} onClose={()=>{
                    BuscarDados();
                    setOpen(false);
                }}/>
            </ModalPage>
        </Stack>
    }
    

    
}