import { Flex, Grid, GridItem, HStack, SimpleGrid, Skeleton, Stack, Text, Textarea, useToast,Icon  } from "@chakra-ui/react";
import { HK4, HK5, PageHK, SubBHK, SubHK, SubHK5, SubTHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm";
import { TextK1 } from "../../../components/text/TextKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react"; 
import { sendFormSocket } from "../../../components/session/SessionKalyst";
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import SelectKMultiple from "../../../components/select/SelectKalystMultiple";
import { LuPackage2, LuPlus, LuTimerOff, LuTruck } from "react-icons/lu";
import { CardInfoText, CardPrice, CardPriceMini } from "../../../components/card/CardValues";
import { viewPrice } from "../../../components/Function";
import { InputKCopy } from "../../../components/input/InputKalystCopy";
import { FaBrazilianRealSign } from "react-icons/fa6";
import QRCode from "react-qr-code";
import BoxDialog from "../../../components/modal/BoxDialog"; 
import { MdOutlineCancel } from "react-icons/md";

export default function InfoWithdraw( {open,setOpen,edit = null,setEdit,prods=[],frets=[],pixes=[]}){  
    //janela 
    const [alertJ,setAlertJ]    =   useState({}); 
    //fim janela

    const [load,setLoadD] = useState(null);
    const [base,setBase] = useState(null);
    const [reload,setReload] = useState(false);  

    //forma pagamento 

    const [produtos,setProdutos] = useState([]);
    const [frete,setFrete] = useState([]);
    const [pixel,setPixel] = useState([]);  
    useEffect(()=>{
        if(edit!==null){
            setLoadD(0);
            setTimeout(()=>{
                sendFormSocket({ modulo:'withdraw',cmd:'info',form:{id:edit},callback:(result)=>{  
                    setLoadD(1); 
                    setBase(result);
                    setReload(true);
                   
                }});
            },0);
        }else{
            setLoadD(1);
        }  
    },[]);   
    let dados = {}; 
    let sendD ={
        loja:LojaSelect,
        produtos:produtos,
        frete:frete,
        pixel:pixel
    }; 
    var sd = {};
    var pd = {};
    var us = {};
    if(base!== null){  
        sendD={
            id:pd.id, 
        }; 
        dados = {};
        sd =base.saldos;
        pd =base.dados;
        us =base.user;
    }  
    let tipos ={
        1:'CNPJ',
        2:'E-mail',
        3:'Telefone',
        4:'Aleatória'
    }; 
    let icones ={
        1:'CNPJ',
        2:'E-mail',
        3:'Telefone',
        4:'Aleatória'
    }; 
    var total = 0; 
    if(!reload){   
        return (
            <Stack>
                 <SubBHK  textAlign='left' fontSize={{base:'14px',sm:'20px'}}   >Saque <Text  as='span' color={'textAdmin.300'}>{us.fantasia}</Text></SubBHK> 
                
                <Flex w={'full'} gap={'20px'} align={'lex-start'}  justify={'space-between'}>
                    <Stack w={'100%'} gap={'0px'} align={'flex-start'}  justify={'flex-start'} > 
                        <SubHK5    textAlign='left'>Dados Usuários</SubHK5> 
                        <CardInfoText  load={load}   title='Razão' variant="admin"  value={us.nome}  />
                        <CardInfoText  load={load}   title='Fantasia' variant="admin"  value={us.fantasia}  />
                        <CardInfoText  load={load}   title='CNPJ' variant="admin"   value={us.documento}  />
                        <Stack w={'100%'} gap={'0px'} mt='16px' align={'flex-start'}  justify={'flex-start'}  p='16px' pt='6px' bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius='8px'> 
                            <Text fontSize={'16px'} color='textAdmin.100' textAlign={'center'}  w='100%' pt='10px' pb='5px'>Titular da Conta</Text>
                            {
                                sd.titular==null?<Stack  width={'full'}  gap={'0px'} h={'48px'} align={'center'} justify={'center'} >
                                    <TextK1 textAlign='center'>Consulta titular da conta não realizada</TextK1>
                                </Stack>:<Stack>
                                    <CardInfoText  load={load}   title='Nome' variant="admin"   value={sd.titular.name}  /> 
                                    <CardInfoText  load={load}   title='CNPJ' variant="admin"   value={sd.titular.document}  />
                                </Stack>
                            }   
                        </Stack>
                    </Stack> 
                    <Stack w={'100%'} gap={'0px'} align={'flex-start'}  justify={'center'}   >
                        <SubHK5   textAlign='left'>Dados Pagamento</SubHK5> 
                        <InputKCopy  leftA={icones[pd.codtip]}  loaded={load} variant='admin'  label={"Chave PIX - "+tipos[pd.codtip]} initial={pd}   name="chave"  autoComplete={false}/> 
                        <InputKCopy  leftA={<FaBrazilianRealSign/>}  loaded={load} variant='admin'   label={"Valor a Pagar"} initial={{valor:viewPrice(pd.valor)}}   name="valor"  autoComplete={false}/>     
                    </Stack> 
                </Flex>   
                <SubHK5   textAlign='left'>Resumo Pagamento</SubHK5> 
                <Stack  w={'full'} gap='0px'  borderRadius='8px' >  
                    <Flex w={'100%'} align={'center'} justify={'space-between'} gap={{base:'10px',md:'20px'}}> 
                        <Text fontSize={'12px'} color='gray.500'>Valor Saque</Text>
                        <Text fontSize={'12px'} color='blue.300'>{viewPrice(pd.valorB)}</Text> 
                    </Flex>
                    <Flex w={'100%'} align={'center'} justify={'space-between'} gap={{base:'10px',md:'20px'}} pb='10px'> 
                        <Text fontSize={'12px'} color='gray.500' >Taxa Saque</Text>
                        <Text fontSize={'12px'} color='red.200'>{(pd.taxa>0?'-':'')+viewPrice(pd.taxa)}</Text>
                    </Flex>
                    <SubTHK ><Text as={'span'} fontSize='12px'  color='textAdmin.200'> Total a pagar:</Text><Text as={'span'} fontSize='22px'  color='textAdmin.300'>  R$ {viewPrice(pd.valor)}</Text></SubTHK>
                </Stack>  
                <BoxDialog param={alertJ}  /> 
            </Stack> 
        )
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}